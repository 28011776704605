import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Stack,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { PcCaseIcon } from "lucide-react";
import React, { useEffect, useState } from "react";
import AdminAppShell from "../../layouts/AdminAppShell";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { useStore } from "../../hooks";
import Datatable from "../../components/DataTable";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  is_active: true,
  role_id: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required")
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Invalid email address format"),
  is_active: Yup.boolean().required("Active status is required"),
  role_id: Yup.string().required("Role ID is required"),
  password: Yup.string().required("Password is required"),
});

const UserManagement = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUser] = useState(null);

  const OverlayOne = () => (
    <ModalOverlay
      bg="blackAlpha.300"
      backdropFilter="blur(10px) hue-rotate(90deg)"
    />
  );

  const OverlayTwo = () => (
    <ModalOverlay
      bg="none"
      backdropFilter="auto"
      backdropInvert="80%"
      backdropBlur="2px"
    />
  );

  const [overlay, setOverlay] = React.useState(<OverlayOne />);
  const [loading, setLoading] = useState(false);
  const isLoading = useStore((state) => state.loading);
  const posInternalUsers = useStore((state) => state.internalUsers);
  const fetchAllUsers = useStore((state) => state.fetchAllUsers);
  const removeInternalUser = useStore((state) => state.removeInternalUser);
  const createInternalUser = useStore((state) => state.createInternalUser);
  const fetchRoles = useStore((state) => state.fetchAllRoles);
  const posRoles = useStore((state) => state.roles);

  useEffect(() => {
    fetchAllUsers();
    fetchRoles();
  }, []);

  const submitUpdate = () => {};

  const handleSubmit = (internalUserDetails: any) => {
    setLoading(true)

    createInternalUser(internalUserDetails).then((res: any) => {
        if (res.id) {
            setLoading(false)
            toast({
                title: "Success",
                description: "Internal user was added successfully!",
                status: "success",
                duration: 9000,
                isClosable: true,
            })
            fetchAllUsers()
            onClose()
        }
    })
  };

  const handleUpdate = () => {};

  const handleViewMore = () => {};

  return (
    <AdminAppShell>
      <Flex alignItems="center">
        <Flex alignItems="center" gap={2}>
          <PcCaseIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Users
          </Box>
        </Flex>
        <Spacer />
        <Button
          onClick={() => {
            setUser(null);
            setOverlay(<OverlayTwo />);
            onOpen();
          }}
        >
          Create User
        </Button>
        <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
          {overlay}
          <ModalContent>
            <ModalHeader>
              {user ? "Update Existing User" : "Create a New User"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={user ? user : initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={user ? submitUpdate : handleSubmit}
              >
                <Form>
                  <Flex
                    direction="row"
                    justifyContent="space-between"
                    gap={"1rem"}
                  >
                    <Stack w={"100%"} direction="column" spacing={8}>
                      <Field name="first_name">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.first_name && form.touched.first_name
                            }
                          >
                            <FormLabel>
                              First Name <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Input {...field} type="text" id="title" />
                            <FormErrorMessage>
                              {form.errors.first_name &&
                                form.touched.first_name &&
                                form.errors.first_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="last_name">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.last_name && form.touched.last_name
                            }
                          >
                            <FormLabel>
                              Last Name <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Input {...field} type="text" id="last_name" />
                            <FormErrorMessage>
                              {form.errors.last_name &&
                                form.touched.last_name &&
                                form.errors.last_name}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="email">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={form.errors.email && form.touched.email}
                          >
                            <FormLabel>
                              Email <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Input {...field} type="email" id="email" />
                            <FormErrorMessage>
                              {form.errors.email &&
                                form.touched.email &&
                                form.errors.email}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="role_id">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.role_id && form.touched.role_id
                            }
                          >
                            <FormLabel>
                              Role <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Select {...field} placeholder="Select option" id="role_id">

                                {posRoles.length > 0 && posRoles.map((role: any, idx: number) => (
                                    <option key={idx} value={role.id} >{role.title}</option>
                                ))}
                              
                              
                            </Select>
                            <FormErrorMessage>
                              {form.errors.role_id &&
                                form.touched.role_id &&
                                form.errors.role_id}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="password">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.password && form.touched.password
                            }
                          >
                            <FormLabel>
                              Password <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Input {...field} type="password" id="password" />
                            <FormErrorMessage>
                              {form.errors.password &&
                                form.touched.password &&
                                form.errors.password}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>

                      <Field name="is_active">
                        {({ field, form }: any) => (
                          <FormControl
                            isInvalid={
                              form.errors.is_active && form.touched.is_active
                            }
                          >
                            <FormLabel>
                              Is Role Active{" "}
                              <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            <Checkbox
                              isChecked={field.value}
                              {...field}
                              colorScheme="teal"
                              size="lg"
                              iconColor="red.500"
                              type="is_active"
                              id="is_active"
                            >
                              Is Active
                            </Checkbox>
                            <FormErrorMessage>
                              {form.errors.is_active &&
                                form.touched.is_active &&
                                form.errors.is_active}
                            </FormErrorMessage>
                          </FormControl>
                        )}
                      </Field>
                    </Stack>
                  </Flex>

                  <Stack direction="row" spacing={4} marginY="1rem">
                    <Button
                      width="100%"
                      colorScheme="teal"
                      variant="solid"
                      type="submit"
                    >
                      {loading ? <Spinner size="md" /> : <Box>Submit</Box>}
                    </Button>
                  </Stack>
                </Form>
              </Formik>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>

      <Box py={6}>
        <Datatable
          headerColumns={[
            {
              Header: "First Name",
              accessorKey: "first_name",
            },
            {
              Header: "Last Name",
              accessorKey: "last_name",
            },
            {
              Header: "Email",
              accessorKey: "email",
            },
            {
              Header: "Is Active",
              accessorKey: "is_active",
            },
          ]}
          data={posInternalUsers}
          dataTableHeading={"Users"}
          handleDelete={removeInternalUser}
          handleUpdate={handleUpdate}
          handleViewMore={handleViewMore}
          isSearch={true}
          isActions={true}
          isLoading={isLoading}
        />
        {/* <GenericTable data={[]}/> */}
      </Box>
    </AdminAppShell>
  );
};

export default UserManagement;
