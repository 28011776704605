import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spinner,
  Stack,
  Text,
  Image,
  Box,
} from "@chakra-ui/react";
import React from "react";
import ThemeShell from "../../layouts/ThemeShell";
import { Formik, Field, Form } from "formik";
import { useAuthStore } from "../../hooks";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import FaipayLogo from "../../assets/FairPayLogo.png";
import { useToast } from "@chakra-ui/react";

const initialValues = {
  email: "",
  password: "",
};

const validate = (values: any) => {
  const errors: any = {};

  if (!values.email) {
    errors.email = "Email is required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required";
  }

  return errors;
};

export default function Login() {
  const signIn = useAuthStore((state) => state.signInUser);
  const loading = useAuthStore((state) => state.loading);
  const navigate = useNavigate();
  const toast = useToast();

  const handleSubmit = (values: any) => {
    signIn(values).then((res: any) => {
      // if user is present
      // navigate to a private route
      console.log(res)
      if (!_.isEmpty(res)) {
        if (res?.detail) {
          toast({
            title: "Oops! Please try again.",
            description: `${res?.detail}`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else {
          toast({
            title: "Hey. Welcome back ",
            description: "Happy to see you again",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          navigate("/");
        }
      }
    });
  };

  return (
    <ThemeShell>
      <Container>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="70vh"
        >
          <Image src={FaipayLogo} alt="Fairpay logo" w={"300px"} />
          <Text fontSize="lg" p={8}>
            Some information about FairPay
          </Text>

          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={handleSubmit}
          >
            <Form>
              <Stack direction="column" spacing={8}>
                <Field name="email">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <FormLabel>Email address</FormLabel>
                      <Input {...field} type="email" id="email" />
                      <FormErrorMessage>
                        {form.errors.email &&
                          form.touched.email &&
                          form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="password">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.password && form.touched.password}
                    >
                      <FormLabel>Password</FormLabel>
                      <Input {...field} type="password" id="password" />
                      <FormErrorMessage>
                        {form.errors.password &&
                          form.touched.password &&
                          form.errors.password}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Stack direction="row" spacing={4}>
                  <Button width="70%" colorScheme="teal" variant="outline">
                    Forgot Password
                  </Button>
                  <Button
                    width="30%"
                    colorScheme="teal"
                    variant="solid"
                    type="submit"
                  >
                    {loading ? <Spinner size="md" /> : <Box>Login</Box>}
                  </Button>
                </Stack>
              </Stack>
            </Form>
          </Formik>
        </Flex>
      </Container>
    </ThemeShell>
  );
}
