import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Spacer,
  Spinner,
  Tag,
  TagLabel,
  TagRightIcon,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";
// import locale from "antd/locale/zh_CN";
// import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import _ from "lodash";
// import { ConfigProvider } from "antd";
// import type { DatePickerProps } from "antd";
// import { DatePicker } from "antd";

// const validate = (values: any) => {
//   const errors: any = {};

//   if (!values.device_id) {
//     errors.device_id = "Device is required";
//   }
// };

// const validateRoute = (values: any) => {
//   const errors: any = {};

//   if (!values.route_id) {
//     errors.route_id = "Route is required";
//   }
// };

const Vehicle = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleVehicle = useStore((state) => state.fetchSingleVehicle);
  const fetchDevices = useStore((state) => state.fetchDevices);
  const fetchRoutes = useStore((state) => state.fetchRoutes);
  const fetchVehicleDeviceRecord = useStore(
    (state) => state.fetchVehicleDeviceRecord
  );
  const mapVehiclesToRoutes = useStore((state) => state.mapVehicleToRoute);
  const mapVehicleToDevice = useStore((state) => state.mapVehicleToDevice);
  const removeVehicleDeviceMapping = useStore(
    (state) => state.removeVehicleToDeviceMapping
  );
  const fetchVehicleDevices = useStore((state) => state.fetchVehicleDevices);
  const posVehicleDevices = useStore((state) => state.vehicleDevices);
  const fetchVehicleRoutes = useStore((state) => state.fetchVehicleRoutes);
  const posVehicleRoutes = useStore((state) => state.vehicleRoutes);
  const posVehicle = useStore((state) => state.vehicle);
  const posDevices = useStore((state) => state.devices);
  const posRoutes = useStore((state) => state.routes);
  const [isActive, setIsActive] = useState("device");
  const [loading, setLoading] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [routeId, setRouteId] = useState("");
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // const [startDate, setStartDate] = useState(new Date());
  const [mappedValidator, setMappedValidator] = useState<any>(null);
  const [isRemapLoading, setIsRemapLoading] = useState(false);

  useEffect(() => {
    console.log(id);
    if (id) {
      fetchSingleVehicle({ id: id });
      fetchVehicleDevices({ id: id });
      fetchVehicleRoutes({ id: id });
      fetchDevices();
      fetchRoutes();
    }
  }, [
    fetchSingleVehicle,
    id,
    fetchDevices,
    fetchRoutes,
    fetchVehicleDevices,
    fetchVehicleRoutes,
  ]);

  const agentDevices = useMemo(
    () => posDevices.filter((device: any) => device.type !== "agent"),
    [posDevices]
  );

  const handleDeviceIdChange = (e: any) => {
    setDeviceId(e.target.value);
  };

  const handleRouteIdChange = (e: any) => {
    setRouteId(e.target.value);
  };

  const triggerDeviceModal = () => {
    // trigger the modal fr confirmation
    onOpen();
  };

  const removeExistingValidatorAndMapNew = () => {
    setIsRemapLoading(true);

    // Get all details pertaiining the existing validator
    // id, vehicle_id, device_id

    // hit the api to retrieve the record that matches the vehicle id and device id
    fetchVehicleDeviceRecord({
      vehicle_id: posVehicleDevices?.id,
      device_id: mappedValidator?.id,
    }).then((res: any) => {
      // console.log("devices mapped", res)

      // check if the res return a valid values
      if (res.id) {
        // hit the remove endpoint to delete the mapping
        fetchSingleVehicle({ id: posVehicleDevices?.id });
        removeVehicleDeviceMapping({
          id: res.id,
          vehicle_id: res.vehicle_id,
          device_id: res.device_id,
        }).then((res: any) => {
          // if successful then send the new mapping
          if (res?.response === "SUCCESS") {
            let deviceDetails = {
              vehicle_id: posVehicleDevices?.id,
              device_id: deviceId,
              enabled: true,
            };

            mapVehicleToDevice(deviceDetails).then((res: any) => {
              setIsRemapLoading(false);
              setLoading(false);
              onClose();
              toast({
                title: "Success",
                description: "Mapped vehicle to device successfully.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });
              fetchVehicleDevices({ id: id });
            });
            // else dislay a toast stating the error that occurred
            setIsRemapLoading(false);
            setLoading(false);
          }
        });
      }
    });
  };

  const handleSubmitDevice = () => {
    setLoading(true);
    const deviceDetails = {
      device_id: deviceId,
      vehicle_id: posVehicle?.id,
      // validity: "",
      enabled: true,
    };

    // given the vehicle ID check whether it contains any mappings
    fetchVehicleDevices({ id: deviceDetails?.vehicle_id }).then((res: any) => {
      if (res?.devices.length > 0) {
        // check whether there is a device with the type validator
        let validator = res?.devices.filter(
          (device: any) => device.type === "validator"
        );

        if (validator.length > 0) {
          // set the mappedValidator state
          setMappedValidator(validator[0]);
          //  launch an alert to notify the user that there is a mapping that exists and whether they wish to change it
          triggerDeviceModal();
        } else {
          mapVehicleToDevice(deviceDetails).then((res: any) => {
            setLoading(false);
            onClose();
            toast({
              title: "Success",
              description: "Mapped vehicle to device successfully.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
            fetchVehicleDevices({ id: deviceDetails?.vehicle_id });
          });
        }
      } else {
        mapVehicleToDevice(deviceDetails).then((res: any) => {
          setLoading(false);
          onClose();
          toast({
            title: "Success",
            description: "Mapped vehicle to device successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          fetchVehicleDevices({ id: deviceDetails?.vehicle_id });
        });
      }
    });
  };

  const handleSubmitRoute = () => {
    setLoading(true);

    const routeDetails = {
      route_id: routeId,
      vehicle_id: posVehicle?.id,
      enabled: true,
    };
    mapVehiclesToRoutes(routeDetails).then((res: any) => {
      setLoading(false);
      toast({
        title: "Success",
        description: "Mapped vehicle to route successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    });
  };

  // const onChange: DatePickerProps["onChange"] = (date: any, dateString) => {
  //   let myDate = date.toISOString().split("T")[0];
  //   setStartDate(myDate);
  // };

  // const onChange = (e: any) => {
  //   setStartDate(e.target.value)
  // }

  return (
    <AdminAppShell>
      {isOpen && (
        <Modal onClose={onClose} isOpen={isOpen} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader></ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {isRemapLoading ? (
                <Flex
                  justifyContent={"center"}
                  direction={"column"}
                  alignItems={"center"}
                >
                  <Spinner size={"xl"} m={"4"} />
                  <Text
                    textAlign={"center"}
                    fontWeight={"bold"}
                    fontSize={"2xl"}
                  >
                    Please wait as we remap the selected device
                  </Text>
                </Flex>
              ) : (
                <>
                  <Text fontWeight={"bold"} textAlign={"center"} paddingY={"4"}>
                    {`${posVehicle?.name}`} is already mapped to a validator.
                    See details below.
                  </Text>

                  <List display={"flex"} justifyContent={"center"}>
                    <ListItem
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <ListIcon as={CarTaxiFrontIcon} color="green.500" />
                      <Box marginRight={"5"} fontWeight={"bolder"}>
                        {mappedValidator?.name}
                      </Box>
                      <Box fontWeight={"bolder"}>
                        <Tag
                          size={"sm"}
                          key={"sm"}
                          variant="outline"
                          colorScheme="blue"
                          marginTop={"0.5"}
                        >
                          <TagLabel>{mappedValidator?.type}</TagLabel>
                          <TagRightIcon as={CarTaxiFrontIcon} />
                        </Tag>
                      </Box>
                    </ListItem>
                  </List>

                  <Text fontWeight={"bold"} textAlign={"center"} paddingY={"2"}>
                    Are you sure that you want to change mapping of{" "}
                    {`${posVehicle?.name}`} vehicle.
                  </Text>
                </>
              )}
            </ModalBody>
            <ModalFooter justifyContent={"space-around"} gap={"4"}>
              <Button onClick={onClose} colorScheme={"red"}>
                Cancel
              </Button>
              <Button
                onClick={removeExistingValidatorAndMapNew}
                colorScheme={"green"}
              >
                I Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Vehicle - {posVehicle ? posVehicle?.name : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      {posVehicle ? (
        <Box>
          <Card align="center">
            <CardBody w={"100%"}>
              <Flex w={"100%"} gap={"8"}>
                <Flex direction={"column"} gap={"3"} w={"40%"}>
                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Name:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posVehicle?.name} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Model:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posVehicle?.model} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Make:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posVehicle?.make} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Type:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posVehicle?.type} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Vehicle Number:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posVehicle?.vehicle_number} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Fuel Type:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posVehicle?.fuel_type} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Vehicle Capacity:{" "}
                    </Heading>
                    <Input
                      readOnly
                      py="2"
                      value={posVehicle?.vehicle_capacity}
                    />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Created:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posVehicle?.created_ts} />
                  </Flex>

                  <Flex alignItems={"center"} gap={"2"}>
                    <Heading size="md" w={"250px"}>
                      Updated:{" "}
                    </Heading>
                    <Input readOnly py="2" value={posVehicle?.updated_ts} />
                  </Flex>
                </Flex>

                <Box w={"60%"}>
                  <Flex
                    direction={"row"}
                    gap={"8"}
                    justifyContent={"space-between"}
                  >
                    <Button
                      colorScheme={"green"}
                      onClick={() => setIsActive("device")}
                    >{`Map ${posVehicle.name} to Device`}</Button>
                    <Button
                      colorScheme={"green"}
                      onClick={() => setIsActive("route")}
                    >{`Map ${posVehicle.name} to Route`}</Button>
                  </Flex>

                  <Box marginY={"10"}>
                    {isActive === "device" ? (
                      <Box>
                        <Text fontWeight={"bolder"} marginBottom={"6"}>
                          Device to Vehicle Mapping
                        </Text>

                        {/* {JSON.stringify(posVehicleDevices)} */}

                        {!_.isEmpty(posVehicleDevices) ? (
                          <>
                            <Text>
                              The following devices have been mapped to{" "}
                              {`${posVehicle.name}`}
                            </Text>
                            <List boxShadow={"lg"} spacing={3} padding="4">
                              {posVehicleDevices.devices.length > 0 ? (
                                posVehicleDevices.devices.map((device: any) => (
                                  <ListItem
                                    key={device.id}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <ListIcon
                                      as={CarTaxiFrontIcon}
                                      color="green.500"
                                    />
                                    <Box
                                      marginRight={"5"}
                                      fontWeight={"bolder"}
                                    >
                                      {device?.name}
                                    </Box>
                                    <Box fontWeight={"bolder"}>
                                      <Tag
                                        size={"sm"}
                                        key={"sm"}
                                        variant="outline"
                                        colorScheme="blue"
                                      >
                                        <TagLabel>{device?.type}</TagLabel>
                                        <TagRightIcon as={CarTaxiFrontIcon} />
                                      </Tag>
                                    </Box>
                                  </ListItem>
                                ))
                              ) : (
                                <Box display={"flex"} flexDirection={"column"} justifyContent={'center'} alignItems={'center'}>
                                  <Text marginY={"4"}>
                                    Vehicle not Mapped to any device
                                  </Text>
                                  <Text color={"red"}>
                                    Please select the validator device and
                                    driver device that will be mapped to this
                                    vehicle
                                  </Text>
                                </Box>
                              )}
                            </List>
                          </>
                        ) : (
                          <></>
                        )}

                        <Box boxShadow={"lg"} p="4">
                          <FormControl>
                            <FormLabel>Vehicle</FormLabel>
                            <Input
                              value={posVehicle.name}
                              marginBottom={"4"}
                              disabled
                              readOnly
                            />
                          </FormControl>
                          <FormControl>
                            <FormLabel>Device</FormLabel>
                            <Select
                              placeholder="Select a Device"
                              id="device_id"
                              onChange={handleDeviceIdChange}
                            >
                              {agentDevices &&
                                agentDevices.map((item: any, index: number) => (
                                  <option key={index} value={item.id}>
                                    {`${item.name} - ${item.serialno}`}
                                  </option>
                                ))}
                            </Select>
                          </FormControl>

                          {/* <FormControl marginY={"6"}>
                            <FormLabel>Validity</FormLabel>
                            <Input type="text" id="validity" onChange={onChange}/>
                            <DatePicker
                              size="large"
                              style={{ width: "100%" }}
                              onChange={onChange}
                            />
                          </FormControl> */}
                        </Box>

                        {deviceId && (
                          <Button
                            colorScheme={"green"}
                            marginY={"4"}
                            w={"100%"}
                            variant="solid"
                            type="submit"
                            onClick={handleSubmitDevice}
                            isDisabled={loading || isRemapLoading}
                          >
                            {loading ? <Spinner /> : <>Submit</>}
                          </Button>
                        )}
                      </Box>
                    ) : (
                      <Box>
                        <Text fontWeight={"bolder"} marginBottom={"6"}>
                          Route to Vehicle Mapping
                        </Text>

                        {!_.isEmpty(posVehicleRoutes) ? (
                          <>
                            <Text>
                              The following routes have been mapped to{" "}
                              {`${posVehicle.name}`}
                            </Text>
                            <List boxShadow={"lg"} spacing={3} padding="4">
                              {posVehicleRoutes.routes.length > 0 ? (
                                posVehicleRoutes.routes.map((route: any) => (
                                  <ListItem
                                    key={route.id}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <ListIcon
                                      as={CarTaxiFrontIcon}
                                      color="green.500"
                                    />
                                    <Box
                                      marginRight={"5"}
                                      fontWeight={"bolder"}
                                    >
                                      {route?.name}
                                    </Box>
                                  </ListItem>
                                ))
                              ) : (
                                <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                  <Text>Vehicle not Mapped to any Route</Text>
                                  <Text color={"red"}>
                                    Please select a route that will be mapped to this
                                    vehicle
                                  </Text>
                                </Box>
                              )}
                            </List>
                          </>
                        ) : (
                          <></>
                        )}

                        <Box>
                          <FormControl>
                            <FormLabel>Vehicle</FormLabel>
                            <Input
                              value={posVehicle.name}
                              marginBottom={"4"}
                              disabled
                              readOnly
                            />
                          </FormControl>

                          <FormControl>
                            <FormLabel>Route</FormLabel>
                            <Select
                              placeholder="Select a Route"
                              id="route_id"
                              onChange={handleRouteIdChange}
                            >
                              {posRoutes &&
                                posRoutes.map((item: any, index: number) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                ))}
                            </Select>
                          </FormControl>
                          {routeId && (
                            <Button
                              colorScheme={"green"}
                              marginY={"4"}
                              w={"100%"}
                              variant="solid"
                              type="submit"
                              onClick={handleSubmitRoute}
                            >
                              {loading ? <Spinner /> : <Box>Submit</Box>}
                            </Button>
                          )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Flex>
            </CardBody>
          </Card>
        </Box>
      ) : (
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"50vh"}
        >
          <Spinner />
          <Text fontWeight={"bolder"}>Please wait. Loading....</Text>
        </Box>
      )}
    </AdminAppShell>
  );
};

export default Vehicle;
