import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  Heading,
  Input,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

const Device = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleDevice = useStore((state) => state.fetchSingleDevice);
  const posDevice = useStore((state) => state.device);

  useEffect(() => {
    if (id) {
      fetchSingleDevice({ id: id });
    }
  }, [fetchSingleDevice, id]);

  return (
    <AdminAppShell>
      <Flex alignItems={"center"} justifyContent={'space-between'}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Device - {posDevice ? posDevice?.name : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box>
        <Card align="center">
          <CardBody>
            <Flex direction={"column"} gap={"3"} justifyContent={"center"}>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Name:{" "}
                </Heading>
                <Input readOnly py="2" value={posDevice?.name} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Make:{" "}
                </Heading>
                <Input readOnly py="2" value={posDevice?.make} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Model:{" "}
                </Heading>
                <Input readOnly py="2" value={posDevice?.model} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Type:{" "}
                </Heading>
                <Input readOnly py="2" value={posDevice?.type} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Mac Adsress:{" "}
                </Heading>
                <Input readOnly py="2" value={posDevice?.mac_address} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  SerialNo:{" "}
                </Heading>
                <Input readOnly py="2" value={posDevice?.serialno} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Created:{" "}
                </Heading>
                <Input readOnly py="2" value={posDevice?.created_ts} />
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={"250px"}>
                  Updated:{" "}
                </Heading>
                <Input readOnly py="2" value={posDevice?.updated_ts} />
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </AdminAppShell>
  );
};

export default Device;
