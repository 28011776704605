import { Box, Button, Card, CardBody, Flex, Heading, Input, Spacer, Text } from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";

const Driver = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const fetchSingleDriver = useStore((state) => state.fetchSingleDriver);
  const posDriver = useStore((state) => state.driver);

  useEffect(() => {
    if (id) {
      fetchSingleDriver({ id: id });
    }
  }, [fetchSingleDriver, id]);

  return <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Driver - {posDriver ? posDriver?.name : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      </Flex>

      <Box>
        <Card align={'center'}>
          <CardBody> 
          <Flex direction={'column'} gap={'3'} justifyContent={'center'}>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Name: </Heading>
                <Input readOnly py="2" value={posDriver?.name}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>National ID: </Heading>
                <Input readOnly py="2" value={posDriver?.national_id}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Contact Number: </Heading>
                <Input readOnly py="2" value={posDriver?.contact_number}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Contact Email: </Heading>
                <Input readOnly py="2" value={posDriver?.contact_email}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Address: </Heading>
                <Input readOnly py="2" value={posDriver?.address}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Gender: </Heading>
                <Input readOnly py="2" value={posDriver?.gender}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>User Code: </Heading>
                <Input readOnly py="2" value={posDriver?.user_code}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Created: </Heading>
                <Input readOnly py="2" value={posDriver?.created_ts}/>
              </Flex>

              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Updated: </Heading>
                <Input readOnly py="2" value={posDriver?.updated_ts}/>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
  </AdminAppShell>;
};

export default Driver;
