import { create, SetState } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import _ from "lodash";
import toast from "react-hot-toast";

const BASEURL = process.env.REACT_APP_BASE_URL;
// const BASEURL = "http://localhost:8000/api/v1";

export const useAuthStore = create<any>()(
  devtools(
    persist(
      (set: SetState<any>) => ({
        loading: false,
        isAuthenticated: false,
        user: null,

        // USER MANAGEMENT

        signInUser: async (userDetails: any) => {
          try {
            set({ loading: true });
            const signInUrl = `${BASEURL}/user/login/access-token`;

            // create the form data
            const userCredentials = new FormData();

            userCredentials.append("username", userDetails.email);
            userCredentials.append("password", userDetails.password);

            const response: any = await fetch(signInUrl, {
              method: "POST",
              body: userCredentials,
              headers: {
                // Set the appropriate headers for form data
                // Note that you don't need to specify 'Content-Type' here, as FormData sets it automatically
              },
            });

            if (response.ok) {
              set({ loading: false });
              const result = await response.json();
              if (!_.isEmpty(result)) {
                // destructre the various params
                const { access_token, user } = result;
                // set the access token to local
                window.localStorage.setItem("jwt_access_token", access_token);
                set({ user: user });
                return user;
              }
            } else {
              set({ loading: false });
              return response.json();
            }
          } catch (error: any) {
            set({ loading: false });
            const errorMessage = error.message
              ? error.message
              : "An error occurred. Please contact support!";
            toast.error(errorMessage, {
              duration: 5000,
              position: "top-center",
              style: { background: "#d00000", color: "#fff" },
            });
            return error;
          }
        },

        signOutUser: async () => {
          try {
            setTimeout(() => {
              window.localStorage.removeItem("jwt_access_token");
            }, 2000);
          } catch (error: any) {
            console.log(error);
            const errorMessage = error.message
              ? error.message
              : "An error occurred. Please contact support!";
            toast.error(errorMessage, {
              duration: 5000,
              position: "top-center",
              style: { background: "#d00000", color: "#fff" },
            });
            set({ loading: false });
          }
        },
      }),
      {
        name: "faipay-pos-storage",
        storage: createJSONStorage(() => window.localStorage),
      }
    )
  )
);