import { create, SetState } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";
import _ from "lodash";
import toast from "react-hot-toast";
import axios from 'axios'

const BASEURL = process.env.REACT_APP_BASE_URL;
// const BASEURL = "http://localhost:8000/api/v1";

export const useReconStore = create<any>()(
  devtools((set: SetState<any>) => ({
    processingFeeList: [],

    downloadProcessingFeeData: async (format: string) => {
        const BASEURL = process.env.REACT_APP_BASE_URL;
      
        try {
          const response = await axios.get(`${BASEURL}/detail_invoice_financial_statements/processing-fee/download`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `processing_fee_data.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadExceptionReport: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
        try {
          const response = await axios.get(`${BASEURL}/master_exceptions/download_detailed_exceptions`, {
            responseType: "blob",
            params: { 
              mst_id: downloadDetails.mstId,
              id: downloadDetails.id,
              file_type: downloadDetails.fileType,
             },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `ExceptionReport.${downloadDetails.fileType === "excel" ? "xlsx" : downloadDetails.fileType}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    }, 

    downloadFinancialTransactionStatement: async (format: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
        try {
          const response = await axios.get(`${BASEURL}/transaction_statements/download`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Financial Transaction Statement.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadTransactionStatement: async (format: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
        try {
          const response = await axios.get(`${BASEURL}/transaction_statuses/download/data`, {
            responseType: "blob",
            params: { file_format: format },
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
            },
          });

      
          const url = window.URL.createObjectURL(new Blob([response.data]));
      
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Transaction Statuses.${format === "excel" ? "xlsx" : format}`);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          console.error("Error downloading the file", error);
        }
    },

    downloadTopUpTransactionReport: async (downloadDetails: any) => {
      const BASEURL = process.env.REACT_APP_BASE_URL;
      
      try {
        const response = await axios.get(`${BASEURL}/master_topup_recon/recon-detail/download`, {
          responseType: "blob",
          params: { format: downloadDetails.format, top_up_date: downloadDetails.top_up_date },
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Top Up Transaction Report.${downloadDetails.format === "excel" ? "xlsx" : downloadDetails.format}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    },

    downloadTransactionMapReport: async (downloadDetails: any) => {

      const BASEURL = process.env.REACT_APP_BASE_URL;

      try {
        const response = await axios.get(`${BASEURL}/transaction/report/download`, {
          responseType: "blob",
          params: { file_format: downloadDetails.format},
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("jwt_access_token")}`,
          },
        });

    
        const url = window.URL.createObjectURL(new Blob([response.data]));
    
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Transactions Report.${downloadDetails === "excel" ? "xlsx" : downloadDetails}`);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error("Error downloading the file", error);
      }
    }
  }))
);
