import React, { useEffect } from "react";
import { useStore } from "../../../hooks";
import AdminAppShell from "../../../layouts/AdminAppShell";
import { useParams, useNavigate} from "react-router-dom";
import {
  Card,
  CardBody,
  Flex,
  Heading,
  Box,
  Input,
  Spacer,
  Button,
} from "@chakra-ui/react";
import { CarTaxiFrontIcon } from "lucide-react";


const Operator: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const fetchSingleOperator = useStore((state) => state.fetchSingleOperator);
  const posOperator = useStore((state) => state.operator);

  useEffect(() => {
    if (id) {
      fetchSingleOperator({ id: id });
    }
  }, [fetchSingleOperator, id]);

  return (
    <AdminAppShell>
      <Flex alignItems={"center"}>
        <Flex alignItems="center" gap={2}>
          <CarTaxiFrontIcon size={35} />
          <Box fontSize="3xl" fontWeight="bold">
            Operator - {posOperator ? posOperator?.name : ""}
          </Box>
        </Flex>
        <Spacer />
        <Button colorScheme="green" onClick={() => navigate(-1)}>Go Back</Button>
      </Flex>

      <Box marginY={'8'}>
        <Card align="center">
          <CardBody>
            <Flex direction={'column'} gap={'3'} justifyContent={'center'}>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Name: </Heading>

                <Input readOnly py="2" value={posOperator?.name}/>
              </Flex>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Code: </Heading>

                <Input readOnly py="2" value={posOperator?.code}/>
              </Flex>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Address: </Heading>

                <Input readOnly py="2" value={posOperator?.address}/>
              </Flex>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Contact Person: </Heading>

                <Input readOnly py="2" value={posOperator?.contact_person}/>
              </Flex>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Contact Number: </Heading>

                <Input readOnly py="2" value={posOperator?.contact_number}/>          
              </Flex>
            </Flex>
            <Flex direction={'column'} gap={'3'} justifyContent={'center'}>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Total Vehicles: </Heading>

                <Input readOnly py="2" value={posOperator?.total_vehicles}/>
              </Flex>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Total Agents: </Heading>

                <Input readOnly py="2" value={posOperator?.total_agents}/>
              </Flex>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Created: </Heading>

                <Input readOnly py="2" value={posOperator?.created_ts}/>
              </Flex>
              <Flex alignItems={"center"} gap={"2"}>
                <Heading size="md" w={'250px'}>Updated: </Heading>

                <Input readOnly py="2" value={posOperator?.updated_ts}/>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </AdminAppShell>
  );
};

export default Operator;
