import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Button,
  Text,
  Center,
  Spinner,
  Select,
  Input,
  Flex,
  Spacer,
  Badge,
  Tooltip,
} from "@chakra-ui/react";
import { EyeIcon, FileDownIcon } from "lucide-react";
import {
  ArrowBackIcon,
  ArrowForwardIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@chakra-ui/icons";

const DataTableThree = ({
  columns,
  fetchUrl,
  tokenKey,
  downloadFunc,
  showActions = true,
  showExports = true,
  handleMore,
  handleDownloadFunc,
}: any) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(12);
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchData(currentPage, pageSize, sortConfig, searchQuery);
  }, [currentPage, pageSize, sortConfig, searchQuery]);

  const fetchData = async (
    page: any,
    size: any,
    sortConfig: any,
    searchQuery: any
  ) => {
    setLoading(true);
    try {
      const response = await axios.get(fetchUrl, {
        params: {
          page,
          size,
          sortField: sortConfig.key,
          sortDirection: sortConfig.direction,
          search: searchQuery,
        },
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem(tokenKey)}`,
        },
      });
      setData(response.data.items);
      setTotalPages(response.data.pages);
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setLoading(false);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handlePageSizeChange = (e: any) => {
    setPageSize(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSort = (key: any) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleDownload = async (format: string) => {
    // You might need to adjust how you're fetching the data for download

    console.log("fooooormat", format);

    if (format === "excel") {
      downloadFunc("excel")
        .then((res: any) => {
          console.log("DOWNLOAD", res);
        })
        .catch((error: any) => {
          console.log("ERROR", error);
        });
    }

    if (format === "csv") {
      downloadFunc("csv")
        .then((res: any) => {
          console.log("DOWNLOAD", res);
        })
        .catch((error: any) => {
          console.log("ERROR", error);
        });
    }
  };

  const handleViewMore = (item: any) => {
    handleMore(item);
  };

  const getStatusBadge = (status: string) => {
    console.log("sdvsd", status);
    const statusMeaning: { [key: string]: string } = {
      Assigned:
        "The transaction has been assigned to a user account and is payable to the PTO",
      Unassigned:
        "The Transaction is valid but has not been assigned to a user account yet.",
      "Technical Loss":
        "The transaction ihas been deemed as a duplicate during processing and is not payable to the PTO",
      "TCH Rejected":
        "The transaction has been rejected by the ABT Back Office according to its processing rules.",
      "TCH Exempt":
        "The transaction has been associated to an Exempt account in the TCH",
      Unpaid:
        "After the 7 day grace period, if the transaction could not be ssigned to any account it will be marked as Unpaid",
    };
    return statusMeaning[status] || "Not Reconciled";
  };

  const getBadgeColor = (status: string) => {
    const colors: { [key: string]: string } = {
      Assigned: "green",
      Unassigned: "red",
      "Technical Loss": "yellow",
      "TCH Rejected": "orange",
      "TCH Exempt": "blue",
      Unpaid: "purple",
    };
    return colors[status] || "gray";
  };

  const getConceptTooltip = (concept: string) => {
    const conceptMeanings: { [key: string]: string } = {
      "CCADJ": "Customer Claim Adjustment: these are debits created against a transaction to represent an adjustment made as per a customer claim (e.g.: the customer was changed an incorrect or excessive fare).",
      "CETA": "Exempt - Transaction Adjustment: these are debits created against a transaction to represent an adjustment made to transform the transaction into an Exempt one (e.g.: 100% discount). This is not currently in place for ABT but has been included in the spec in case the Exempt figure becomes relevant in future.",
      "ETOLL": "Guaranteed - TCH Exempt - Transaction: this concept groups all Exempt transactions received and processed where the payment is guaranteed to the PTO.",
      "GTOLL": "Guaranteed Transaction: this concept groups all regular transactions received and processed, which were assigned to accounts, and where the payment is guaranteed to the PTO.",
      "NGTOLL": "Assigned - Non-Guaranteed Transaction: this concept groups all regular transactions received and processed, which were assigned to accounts, and where the payment is not guaranteed to the PTO.",
      "PTOLL": "Unassigned – Non-Guaranteed Transaction: this concept groups all regular transactions received and processed, which were not assigned to accounts yet, and therefore the payment is not guaranteed to the PTO. Note that the transactions included in this concept will 'move' to a different concept in subsequent statements once they have reached their final status.",
      "RTOLL": "TCH Rejected - Transaction: this concept groups all regular transactions received and processed, which were deemed rejected, therefore not payable to the PTO.",
      "TTOLL": "TCH Duplicated - Transaction: this concept groups all regular transactions received and processed, which were deemed as duplicates, therefore not payable to the PTO.",
      "UTOLL": "Non-Guaranteed - Unpaid – Transaction: this concept groups all regular transactions received and processed, which were deemed as unpaid (not possible to assign to an account after the 7-day grace period expired), therefore not payable to the PTO.",
      "GPFEE": "Guaranteed Transaction Processing Fee: this is the processing fee calculated for all transactions marked as Guaranteed in current statement (ETOLL and GTOLL groups).",
      "NGPFEE": "Non-Guaranteed Transaction Processing Fee: this is the processing fee calculated for all transactions marked as Non-Guaranteed in current statement, including the 'failed' states (NGTOLL, RTOLL, TTOLL, and UTOLL groups)."
    };
    return conceptMeanings[concept] || "Unknown concept";
  };

  return (
    <TableContainer width="100%">
      <Flex mb={4} justifyContent={"space-between"} alignItems={"center"}>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          width={"50%"}
        />

        <Spacer />

        {showExports && (
          <Box display="flex" justifyContent="space-between" mt={2} gap={"4"}>
            <Button onClick={() => handleDownload("csv")}>CSV</Button>
            <Button onClick={() => handleDownload("excel")}>Excel</Button>
            {/* <Button onClick={() => handleDownload("pdf")}>PDF</Button> */}
          </Box>
        )}
      </Flex>
      {loading ? (
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
          h="400px"
        >
          <Spinner size="xl" />
          <Box fontWeight={"bold"} my={"4"}>
            Please wait as we load data...
          </Box>
        </Flex>
      ) : (
        <>
          <TableContainer overflowX="auto">
            <Table size={"sm"} variant={"simple"} width="100%">
              <Thead>
                <Tr>
                  {columns.map((column: any) => (
                    <Th
                      key={column.accessor}
                      onClick={() => handleSort(column.accessor)}
                      cursor="pointer"
                    >
                      {column.Header}{" "}
                      {sortConfig.key === column.accessor
                        ? sortConfig.direction === "asc"
                          ? "↑"
                          : "↓"
                        : ""}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {data.map((item: any) => (
                  <Tr key={item.id}>
                    {columns.map((column: any) => (
                      <Td key={column.accessor}>
                        {column.accessor === "accounting_date" ? (
                          new Date(item[column.accessor]).toLocaleDateString()
                        ) : column.accessor === "status_meaning" ? (
                          <Tooltip
                            label={getStatusBadge(item[column.accessor])}
                            aria-label="Status Tooltip"
                            placement="top-end"
                          >
                            <Badge
                              colorScheme={getBadgeColor(item[column.accessor])}
                            >
                              {item[column.accessor]}
                            </Badge>
                          </Tooltip>
                        ) : column.accessor === "fin_transaction_concept" ||
                          column.accessor ===
                            "invoicefin_transaction_concept" ? (
                          <Tooltip
                            label={getConceptTooltip(item[column.accessor])}
                            aria-label="Concept Tooltip"
                            placement="top-end"
                          >
                            {item[column.accessor]}
                          </Tooltip>
                        ) : (
                          item[column.accessor]
                        )}
                      </Td>
                    ))}

                    {showActions && (
                      <Td>
                        <Button
                          colorScheme="blue"
                          size="xs"
                          variant={"ghost"}
                          borderRadius={"full"}
                          onClick={() => handleViewMore(item)}
                        >
                          <EyeIcon />
                        </Button>
                        <Button
                          colorScheme="green"
                          size="xs"
                          variant={"ghost"}
                          borderRadius={"full"}
                          onClick={() => handleDownloadFunc(item)}
                          ml={2}
                        >
                          <FileDownIcon />
                        </Button>
                      </Td>
                    )}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      )}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={6}
        gap={"2"}
      >
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <ArrowLeftIcon />
        </Button>
        <Button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <ArrowBackIcon />
        </Button>

        <Box>
          <Text mt={"4"} fontWeight={"bold"}>
            Page {currentPage} of {totalPages}
          </Text>
          {/* <Select
            value={pageSize}
            onChange={(e) => setPageSize(parseInt(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
          </Select> */}
        </Box>

        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <ArrowForwardIcon />
        </Button>
        <Button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <ArrowRightIcon />
        </Button>
      </Box>
    </TableContainer>
  );
};

export default DataTableThree;
